import React, { createContext, useContext, useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase'; // Import your Firebase config

// Create the AuthContext
const AuthContext = createContext();

// Create AuthProvider component to wrap around the app
export const AuthProvider = ({ children }) => {
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Set up Firebase authentication state observer
    const unsubscribe = onAuthStateChanged(auth, (user) => {
        console.log('onAuthStateChanged triggered:', user);

        if (user) {
        const formattedPhoneNumber = user.phoneNumber.replace('+91', ''); // Remove country code
        setPhoneNumber(formattedPhoneNumber);
        setUser(user);
      } else {
        setPhoneNumber(null);
        setUser(null);
      }
    });

    // Cleanup the observer on unmount
    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ phoneNumber, setPhoneNumber, user, setUser  }}>
      {children}
    </AuthContext.Provider>
  );
};

// Hook to use the AuthContext values
export const useAuth = () => {
  return useContext(AuthContext);
};
