import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';  // Import useParams to get route params
import { Camera, Edit2, Loader  } from 'lucide-react'; // Icons for photo upload

const ProfilePage = () => {
  const { phoneNumber } = useParams(); // Get phoneNumber from the route
  const [profile, setProfile] = useState({
    name: '',
    email: '',
    role: '',
    phone_number: '',
  });
  const [loading, setLoading] = useState(true);
  const [profileError, setProfileError] = useState(null);

  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [contributions, setContributions] = useState([]); // State for contributions
  const [profilePhoto, setProfilePhoto] = useState(null); // State for profile photo
  const [uploadingPhoto, setUploadingPhoto] = useState(false);
  
  useEffect(() => {
    fetchUserProfile();
    fetchUserContributions(); // Fetch contributions when the component mounts

  }, [phoneNumber]);

  const fetchUserProfile = async () => {
    try {
      const response = await fetch(`/api/users/${phoneNumber}`);
      if (!response.ok) {
        throw new Error(`Failed to fetch profile: ${response.statusText}`);
      }
      const data = await response.json();
      setProfile({
        name: data.name || '',
        email: data.email || '',
        role: data.role || '',
        phone_number: data.phone_number || '',
      });
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const fetchUserContributions = async () => {
    try {
      const response = await fetch(`/api/contributions/user/${phoneNumber}`);
      if (!response.ok) {
        throw new Error(`Failed to fetch contributions: ${response.statusText}`);
      }
      const data = await response.json();
      setContributions(data);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    try {
      const response = await fetch(`/api/users/${phoneNumber}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: profile.name,
          email: profile.email,
          role: profile.role,
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to update profile: ${response.statusText}`);
      }

      const updatedData = await response.json();
      setProfile({
        ...profile,
        ...updatedData,
      });

      setIsEditing(false);
      setSuccessMessage('Profile updated successfully!');
      setTimeout(() => setSuccessMessage(''), 3000); // Clear success message after 3 seconds
    } catch (err) {
      setError(err.message);
    }
  };

  const handleProfilePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePhoto(reader.result);
        uploadProfilePhoto(reader.result); // Upload the photo when selected
      };
      reader.readAsDataURL(file);
    }
  };

  // Upload the profile photo
  const uploadProfilePhoto = async (photoBase64) => {
    try {
      setUploadingPhoto(true);
      const response = await fetch(`/api/users/${phoneNumber}/photo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          photo_base64: photoBase64.split(',')[1], // Remove the base64 metadata
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to upload profile photo');
      }

      setUploadingPhoto(false);
      setSuccessMessage('Profile photo updated successfully!');
      setTimeout(() => setSuccessMessage(''), 3000);
    } catch (err) {
      setError(err.message);
      setUploadingPhoto(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-black flex items-center justify-center">
        <Loader className="w-8 h-8 text-blue-500 animate-spin" />
      </div>
    );  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="min-h-screen flex flex-col justify-center bg-gray-900 text-white p-6 rounded-lg shadow-lg">
      <h1 className="text-3xl font-bold mb-4">Profile</h1>

      {successMessage && (
        <div className="bg-green-500 text-white p-3 mb-4 rounded">
          {successMessage}
        </div>
      )}

      {/* Profile Photo Section */}
      <div className="flex items-center justify-center mb-6">
        <div className="relative">
          <img
            src={profilePhoto || '/default-profile.png'}
            alt="Profile"
            className="w-32 h-32 rounded-full object-cover border-2 border-gray-300"
          />
          <label htmlFor="profilePhoto" className="absolute bottom-0 right-0 bg-blue-600 p-1 rounded-full cursor-pointer">
            <Camera className="w-6 h-6 text-white" />
          </label>
          <input
            id="profilePhoto"
            type="file"
            accept="image/*"
            onChange={handleProfilePhotoChange}
            className="hidden"
          />
        </div>
      </div>

      <div className="mb-4">
        <label className="block font-semibold mb-1">Phone Number:</label>
        <input
          type="text"
          value={profile.phone_number}
          className="w-full p-2 rounded bg-gray-800 text-gray-400"
          readOnly
        />
      </div>

      <div className="mb-4">
        <label className="block font-semibold mb-1">Name:</label>
        <input
          type="text"
          name="name"
          value={profile.name}
          onChange={handleInputChange}
          className="w-full p-2 rounded bg-gray-800"
          disabled={!isEditing}
        />
      </div>

      <div className="mb-4">
        <label className="block font-semibold mb-1">Email:</label>
        <input
          type="email"
          name="email"
          value={profile.email}
          onChange={handleInputChange}
          className="w-full p-2 rounded bg-gray-800"
          disabled={!isEditing}
        />
      </div>

      <div className="mb-4">
        <label className="block font-semibold mb-1">Role:</label>
        <input
          type="text"
          name="role"
          value={profile.role}
          onChange={handleInputChange}
          className="w-full p-2 rounded bg-gray-800"
          disabled={!isEditing}
        />
      </div>

      <div className="flex items-center justify-between mb-6">
        {isEditing ? (
          <>
            <button
              onClick={handleSave}
              className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded"
            >
              Save
            </button>
            <button
              onClick={() => setIsEditing(false)}
              className="bg-gray-500 hover:bg-gray-600 text-white py-2 px-4 rounded"
            >
              Cancel
            </button>
          </>
        ) : (
          <button
            onClick={() => setIsEditing(true)}
            className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded"
          >
            <Edit2 className="inline-block mr-1 w-5 h-5" /> Edit Profile
          </button>
        )}
      </div>

        {/* Contributions Section */}
<div className="mt-8">
  <h2 className="text-2xl font-bold mb-4 text-center">Contributions</h2>
  {contributions.length > 0 ? (
    <div className="space-y-4">
      {contributions.map((contribution) => (
        <div
          key={contribution.id}
          className="bg-gray-800 p-4 rounded-lg shadow-md flex items-center"
        >
          {/* Photo on the left */}
          <img
            src={contribution.photo_url}
            alt="Contribution"
            className="w-16 h-16 object-cover rounded-lg"
          />

          {/* Farm ID and Timestamp on the right */}
          <div className="ml-4 text-gray-200">
            <p className="font-semibold">Farm ID: {contribution.farm_id}</p>
            <p className="text-sm text-gray-400">
              {new Date(contribution.timestamp).toLocaleString()}
            </p>
          </div>
        </div>
      ))}
    </div>
  ) : (
    <div className="text-gray-400 text-center">No contributions yet.</div>
  )}
</div>
    </div>
  );
};

export default ProfilePage;
