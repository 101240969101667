import React, { useState, useRef, useEffect } from 'react';
import ProfileIcon from './ProfileIcon';
import { User } from 'lucide-react';

import CameraScreen from './CameraScreen';
import LocationSelectionScreen from './LocationSelectionScreen';
import PreviewScreen from './PreviewScreen';
import CropInputScreen from './CropInputScreen';
import PhotoSelectionScreen from './PhotoSelectionScreen';
import { useAuth } from '../AuthContext'; // Import the useAuth hook
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import icon from '../leaf.png';


const FarmRegistration = ({ onSuccess }) => {
  

  const [currentScreen, setCurrentScreen] = useState('photo-selection');
  const [photo, setPhoto] = useState(null);
  const [location, setLocation] = useState(null);
  const [timestamp, setTimestamp] = useState(null);
  const [cropName, setCropName] = useState('');
  const [additionalPhotos, setAdditionalPhotos] = useState([]);
  const [error, setError] = useState('');
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const mounted = useRef(true);
  const { phoneNumber, setPhoneNumber, user, setUser } = useAuth(); // Get phone number from AuthContext
  const navigate = useNavigate(); // Get navigate function from useNavigate

  useEffect(() => {
    if (!phoneNumber) {
      console.error("Phone number is missing in FarmRegistration, redirecting to login page.");
      setTimeout(() => {
        if (mounted.current) navigate('/'); // Redirect to the AuthFlow login page if no phone number
      }, 100); // Redirect to the AuthFlow login page if no phone number
    } else {
      console.log("Phone number received in FarmRegistration:", phoneNumber);
    }
    mounted.current = true;
    return () => {
      mounted.current = false;
      // Clean up any ongoing processes
      resetForm();
    };
  }, [phoneNumber, navigate]);

  const resetForm = () => {
    setPhoto(null);
    setLocation(null);
    setTimestamp(null);
    setCropName('');
    setAdditionalPhotos([]);
    setError('');
    setSubmitSuccess(false);
    setCurrentScreen('photo-selection');
  };

  
  

  const handleSuccess = () => {
    if (mounted.current) {
      setSubmitSuccess(true);
      // Use setTimeout for navigation to ensure cleanup
      setTimeout(() => {
        if (mounted.current) {
          if (onSuccess) onSuccess();
          resetForm();
        }
      }, 1500);
    }
  };

  

  const screenProps = {
    photo,
    setPhoto,
    location,
    setLocation,
    timestamp,
    setTimestamp,
    cropName,
    setCropName,
    additionalPhotos,
    setAdditionalPhotos,
    error,
    setError,
    setCurrentScreen,
    submitSuccess,
    setSubmitSuccess,
    onSuccess: handleSuccess,
    resetForm, 
    mounted
  };

  const renderScreen = () => {
    switch (currentScreen) {
      case 'photo-selection':
        return <PhotoSelectionScreen {...screenProps} />;
      case 'camera':
        return <CameraScreen {...screenProps} />;
      case 'location-selection':
        return <LocationSelectionScreen {...screenProps} />;
      case 'crop':
        return <CropInputScreen {...screenProps} />;
      case 'preview':
        return <PreviewScreen {...screenProps} />;
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-black p-4 relative">
      <div className="max-w-md mx-auto">
      <div className="flex items-center justify-center mb-1">
        <img 
          src={icon} 
          alt="Logo" 
          className="w-11 h-11 mr-3 object-contain"
        />
        <h1 className="text-4xl font-bold text-center">
          <span className="text-white">coop</span>
          <span className="text-blue-500">Farms</span>
        </h1>
      </div>
        {/* Profile Icon in the top-right corner */}
        <div className="absolute top-4 right-4">
          <ProfileIcon phoneNumber={phoneNumber} />
        </div>
        {renderScreen()}
      </div>
    </div>
  );
};

export default FarmRegistration;