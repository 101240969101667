import React, { useState, useEffect } from 'react';
import { MapPin, Calendar, Phone, Loader, Crop, ImageOff, X as CloseIcon, User,LogOut } from 'lucide-react';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import SlidePanel from './SlidePanel';
import ProfileIcon from './FarmRegistration/ProfileIcon';
import Farm from './Farm'; // Import the new Farm component
import { useAuth } from './AuthContext'; // Import the useAuth hook
import { signOut } from 'firebase/auth'; // Import signOut function
import { auth } from './firebase'; // Import your Firebase config
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import icon from './leaf.png';

const FarmList = ({ onSuccess }) => {
  const [farms, setFarms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [imageErrors, setImageErrors] = useState({});
  const [selectedFarm, setSelectedFarm] = useState(null);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const { phoneNumber } = useAuth(); // Get phone number from AuthContext
  const navigate = useNavigate(); // Get navigate function

  console.log(phoneNumber);
  useEffect(() => {
    fetchFarms();
  }, []);

  const fetchFarms = async () => {
    try {
      const response = await fetch('/api/farms-all');
      const data = await response.json();
      if (data) {
        setFarms(data);
      } else {
        throw new Error('Failed to fetch farms');
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };


  
  

  const handleFarmClick = (farm) => {
    setSelectedFarm(farm);
    setIsPanelOpen(true);
  };

  const closePanel = () => {
    setIsPanelOpen(false);
    setTimeout(() => setSelectedFarm(null), 300); // Clear data after animation
  };
  
  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/'); // Redirect to the login page
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };
  const handleRegisterNewFarm = () => {
    navigate('/registration');
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-black flex items-center justify-center">
        <Loader className="w-8 h-8 text-blue-500 animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-gray-800 to-gray-900 bg-black p-4">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-5xl font-bold mb-12">
            <span className="text-white">coop</span>
            <span className="text-blue-500">Farms</span>
          </h1>
          <div className="bg-red-900 text-white p-4 rounded-lg">
            {error}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-black p-4 relative">
      <div className="max-w-4xl mx-auto">
        
      <div className="flex items-center justify-center mb-1">
        <img 
          src={icon} 
          alt="Logo" 
          className="w-11 h-11 mr-3 object-contain"
        />
        <h1 className="text-4xl font-bold text-center">
          <span className="text-white">coop</span>
          <span className="text-blue-500">Farms</span>
        </h1>
      </div>

         {/* Profile Icon in the top-left corner */}
      {/* Profile Icon and Logout Button in the top-right corner */}
      <div className="absolute top-4 right-4 flex items-center space-x-4">
        <ProfileIcon phoneNumber={phoneNumber} />
        <button
          onClick={handleLogout}
          className="p-2 rounded-full hover:bg-gray-700 transition-colors"
        >
          <LogOut className="w-9 h-9 text-white" />
        </button>
        <span className="absolute top-12 left-14 text-s text-white">Logout</span>

      </div>

      {/* Register New Farm Button in the bottom-right corner */}
      {/* Register New Farm Button as a Floating Action Button */}
      <div className="fixed bottom-8 right-8">
        <button
          onClick={handleRegisterNewFarm}
          className="p-4 rounded-full bg-green-500 hover:bg-green-600 text-white shadow-lg transition-colors"
        >
          <Crop className="w-6 h-6" />
        </button>
      </div>

        {/* Farm List Grid */}
        <div className="w-full max-w-[800px] mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 gap-3 mt-8">          {farms.map((farm) => (
            <div
              key={farm.id}
              onClick={() => handleFarmClick(farm)}
              className="bg-gray-800 rounded-lg p-3 cursor-pointer hover:bg-gray-700 transition-colors "
            >
              <Farm farm={farm} />
            </div>
          ))}
        </div>

        {farms.length === 0 && (
          <div className="text-center text-gray-400 py-12 bg-gray-900 rounded-lg">
            <p className="text-xl">No farms registered yet.</p>
            <p className="text-sm mt-2">Registered farms will appear here.</p>
          </div>
        )}

        {/* Slide Panel */}
        <SlidePanel 
        farm={selectedFarm} 
        isPanelOpen={isPanelOpen} 
        closePanel={closePanel}
        imageErrors={imageErrors}
        
      />


        {/* Backdrop for mobile */}
        {isPanelOpen && (
          <div 
            className="fixed inset-0 bg-black bg-opacity-50 md:hidden"
            onClick={closePanel}
          />
        )}


      </div>
    </div>
  );
};

export default FarmList;

