// lib/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyA8ktxgE4M5IZa5c0ucIPoG2ziiyCUxp9A",
  authDomain: "coopfarms-86ef4.firebaseapp.com",
  projectId: "coopfarms-86ef4",
  storageBucket: "coopfarms-86ef4.appspot.com",
  messagingSenderId: "729181661447",
  appId: "1:729181661447:web:11dcf462db332413fe3da2",
  measurementId: "G-2M73MQZ5GQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
auth.languageCode = 'en'; // Set the language of SMS if needed

export { auth };
