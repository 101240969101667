import React, { useRef, useEffect, useState  } from 'react';
import { Camera, Loader } from 'lucide-react';
import { getLocation } from './utils';

const CameraScreen = ({ 
  setPhoto, 
  setLocation, 
  setTimestamp, 
  setCurrentScreen, 
  error, 
  setError 
}) => {
  const videoRef = useRef(null);
  const streamRef = useRef(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isCapturing, setIsCapturing] = useState(false); // New state to manage capturing process

  const startCamera = async () => {
    setError(''); // Clear any previous errors

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ 
        video: true, 
        audio: false 
      });
      streamRef.current = stream;
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (err) {
      console.error('Error accessing camera:', err);

      setError('Unable to access camera. Please make sure you have given camera permissions.');
    }
  };

  const stopCamera = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
    }
    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }
  };

  const capturePhoto = async () => {
    setIsLoading(true);
    setError(''); // Clear previous errors

    try {

      setIsLoading(true);

      // Inform the user that location is being obtained
      console.log('Requesting location...');
      const locationData = await getLocation();
      console.log('Location obtained:', locationData);

      const currentTime = new Date().toISOString();
      
      const video = videoRef.current;
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(video, 0, 0);
      const base64Photo = canvas.toDataURL('image/jpeg');
      
      setLocation(locationData);
      setTimestamp(currentTime);
      setPhoto(base64Photo);
      stopCamera();
      setCurrentScreen('crop');
    } catch (err) {
      console.error('Error capturing photo:', err);
      setError(err.message || 'Failed to capture');
    } finally {
      setIsLoading(false);
      setIsCapturing(false); // Reset capturing state

    }
  };

  useEffect(() => {
    startCamera();
    return () => stopCamera();
  }, []);

  return (
    <div className="relative">
      <div className="bg-gray-900 rounded-lg p-4 mb-4">
        <p className="text-white text-center">
        Please take a photo of your farm. Try to stand inside the farm,
        preferably at the center.        </p>
      </div>

      <div className="relative aspect-video mb-4 rounded-lg overflow-hidden bg-gray-800">
        <video
          ref={videoRef}
          autoPlay
          playsInline
          className="w-full h-full object-cover"
        />
        {isLoading && (
          <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <Loader className="w-8 h-8 text-white animate-spin" />
            <p className="text-white">Obtaining your location...</p>
          </div>
        )}
      </div>

      {error && (
        <div className="bg-red-900 text-white p-3 rounded-lg mb-4">
          {error}
        </div>
      )}

      <button
        onClick={capturePhoto}
        disabled={isLoading || isCapturing}
        className={`w-full bg-blue-500 text-white py-3 rounded-lg font-semibold transition-colors flex items-center justify-center gap-2 ${
          isLoading || isCapturing ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'
        }`}      
        
        >
        <Camera className="w-5 h-5" />
        Take Photo
      </button>
    </div>
  );
};

export default CameraScreen;
