import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import NDVIChart from './NDVIChart';
import { MapPin, Calendar, Phone, Loader, Crop, X as CloseIcon, Plus  } from 'lucide-react';
import React, { useState, useEffect, useRef } from 'react';

const SlidePanel = ({ farm, isPanelOpen, closePanel }) => {
    const [ndviData, setNdviData] = useState(null);
    const [ndviLoading, setNdviLoading] = useState(false);
    const [ndviError, setNdviError] = useState(null);
    const [grapeData, setGrapeData] = useState(null);
    const [grapeLoading, setGrapeLoading] = useState(false);
    const [grapeError, setGrapeError] = useState(null);
    const [phenologyData, setPhenologyData] = useState(null);
    const [phenologyLoading, setPhenologyLoading] = useState(false);
    const [phenologyError, setPhenologyError] = useState(null);
    const [contributions, setContributions] = useState([]);
    const [loadingContributions, setLoadingContributions] = useState(true);
    const [contributionError, setContributionError] = useState(null);
    const phoneNumber = localStorage.getItem('phoneNumber');
    const [newPhoto, setNewPhoto] = useState(null); // State for holding the new photo
    const [uploading, setUploading] = useState(false); // State for upload status
    const [uploadError, setUploadError] = useState(null);
    const [showPhotoOptions, setShowPhotoOptions] = useState(false); // State for showing photo options pop-up
    const panelRef = useRef(null); // Reference for the slide panel to detect outside clicks

    console.log(farm);
    console.log(phoneNumber)

    const fetchContributions = async (farmId) => {
      try {
        setLoadingContributions(true);
        const response = await fetch(`/api/contributions/farm/${farmId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch contributions');
        }
        const data = await response.json();
        setContributions(data);
      } catch (error) {
        setContributionError(error.message);
      } finally {
        setLoadingContributions(false);
      }
    };

    const handleFileChange = (e) => {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setNewPhoto(reader.result.split(',')[1]); // Get base64 string after the comma
          uploadPhoto(reader.result.split(',')[1]); // Directly upload the photo once selected
        };
        reader.readAsDataURL(file);
      }
    };
  
    const handleCameraCapture = (e) => {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setNewPhoto(reader.result.split(',')[1]); // Get base64 string after the comma
          uploadPhoto(reader.result.split(',')[1]); // Directly upload the photo once captured
        };
        reader.readAsDataURL(file);
      }
    };

    const uploadPhoto = async (base64Photo) => {
      try {
        setUploading(true);
        const response = await fetch('/api/contributions', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            farm_id: farm.id,
            user_id: '9876543211', // Hardcoded user ID, change as per requirement
            photo_base64: base64Photo,
          }),
        });
  
        if (!response.ok) {
          throw new Error('Failed to upload photo');
        }
  
        // After successful upload, refetch the contributions to include the new photo
        await fetchContributions(farm.id);
  
        setNewPhoto(null); // Clear the photo input after successful upload
        setUploadError(null);
      } catch (error) {
        setUploadError(error.message);
      } finally {
        setUploading(false);
      }
    };

    const togglePhotoOptions = () => {
      setShowPhotoOptions((prev) => !prev);
    };



    const fetchNDVIData = async (boundary) => {
        try {
            setNdviLoading(true);
            setNdviError(null);

            const requestBody = {
                type: "Feature",
                geometry: {
                    type: boundary.geometry.type,
                    coordinates: boundary.geometry.coordinates
                },
                properties: {}
            };

            const response = await fetch('/api/ndvi-timeseries', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody)
            });

            if (!response.ok) {
                throw new Error('Failed to fetch NDVI data');
            }

            const data = await response.json();
            if (data.success) {
                const sortedData = data.data.sort((a, b) => new Date(a.date) - new Date(b.date));
                setNdviData(sortedData);
            } else {
                throw new Error(data.error || 'Failed to get NDVI data');
            }
        } catch (error) {
            setNdviError(error.message);
        } finally {
            setNdviLoading(false);
        }
    };

    const fetchGrapeClassification = async (boundary) => {
        try {
            setGrapeLoading(true);
            setGrapeError(null);

            const requestBody = {
                type: "Feature",
                geometry: {
                    type: "Polygon",
                    coordinates: boundary.geometry.coordinates
                },
                properties: {}
            };

            const response = await fetch('/api/classify-grape', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody)
            });

            if (!response.ok) {
                throw new Error('Failed to classify grape field');
            }

            const data = await response.json();
            if (data.success) {
                setGrapeData(data);
            } else {
                throw new Error(data.error || 'Failed to get classification');
            }
        } catch (error) {
            setGrapeError(error.message);
        } finally {
            setGrapeLoading(false);
        }
    };

    const formatDate = (dateString) => {
      return new Date(dateString).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
      });
  };

    const fetchPhenologyDates = async (boundary) => {
        try {
            setPhenologyLoading(true);
            setPhenologyError(null);

            const requestBody = {
                type: "Feature",
                geometry: boundary.geometry,
                properties: {}
            };

            const response = await fetch('/api/phenology-dates', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody)
            });
            console.log(response)
            if (!response.ok) {
                throw new Error('Failed to fetch phenology dates');
            }

            const data = await response.json();
            if (data.success) {
                setPhenologyData(data);
            } else {
                throw new Error(data.message || 'Failed to get phenology dates');
            }
        } catch (error) {
            setPhenologyError(error.message);
        } finally {
            setPhenologyLoading(false);
        }
    };

    const PhenologyDates = ({ data }) => {
      if (!data || !data.summary) return null;
      
      const formatDate = (dateStr) => {
          return new Date(dateStr).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          });
        };
    
      const dateTypes = {
        'fruit_pruning_dates': 'Fruit Pruning',
        'harvest_dates': 'Expected Harvest',
        'post_harvest_dates': 'Post-Harvest Pruning'
      };
    
      if (!data?.success || !data?.summary) {
          return (
            <div className="text-gray-400 text-center py-4">
              No phenology data available
            </div>
          );
        }

        return (
          <div className="space-y-4">
            {Object.entries(dateTypes).map(([key, label]) => {
              const dateInfo = data.summary[key];
              const { past_dates, upcoming_dates } = dateInfo;
      
              return (
                <div key={key} className="space-y-2">
                  <h4 className="text-white font-medium text-sm border-b border-gray-700 pb-1">
                    {label}
                  </h4>
      
                  {/* Upcoming Dates */}
                  <div className="bg-gray-700/50 rounded-lg p-2">
                    <h5 className="text-xs text-gray-300 mb-1">Upcoming</h5>
                    {upcoming_dates.length > 0 ? (
                      upcoming_dates.map((date, idx) => (
                        <div key={idx} className="flex items-center justify-between mb-1">
                          <span className="text-green-400 text-sm">{formatDate(date.date)}</span>
                          <span className="text-xs text-gray-400">{date.count} pts ({date.percentage.toFixed(1)}%)</span>
                        </div>
                      ))
                    ) : (
                      <p className="text-gray-400 text-xs">No upcoming dates predicted</p>
                    )}
                  </div>
      
                  {/* Past Dates */}
                  <div className="bg-gray-700/50 rounded-lg p-2">
                    <h5 className="text-xs text-gray-300 mb-1">Past Events</h5>
                    {past_dates.length > 0 ? (
                      past_dates.map((date, idx) => (
                        <div key={idx} className="flex items-center justify-between mb-1">
                          <span className="text-gray-300 text-sm">{formatDate(date.date)}</span>
                          <span className="text-xs text-gray-400">{date.count} pts ({date.percentage.toFixed(1)}%)</span>
                        </div>
                      ))
                    ) : (
                      <p className="text-gray-400 text-xs">No past events recorded</p>
                    )}
                  </div>
                </div>
              );
            })}
      
            <div className="text-xs text-gray-500 mt-3 border-t border-gray-700 pt-2">
              Analysis based on {data.total_points} points
              <br />
              Last updated: {formatDate(data.analysis_date)}
            </div>
          </div>
        );
      };

    

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (panelRef.current && !panelRef.current.contains(event.target)) {
          setShowPhotoOptions(false); // Close pop-up if clicked outside
        }
      };
  
      // Add event listener when the panel is open
      if (isPanelOpen) {
        document.addEventListener('mousedown', handleClickOutside);
      } else {
        document.removeEventListener('mousedown', handleClickOutside);
      }
  

      if (farm?.id) {
        fetchContributions(farm.id);
      }
        if (farm?.farm_boundary) {
            fetchNDVIData(farm.farm_boundary);
            fetchGrapeClassification(farm.farm_boundary);
            fetchPhenologyDates(farm.farm_boundary);
        }
        return () => {
            setNdviData(null);
            setNdviError(null);
            document.removeEventListener('mousedown', handleClickOutside);

        };
    }, [farm, isPanelOpen]);

    if (!farm) return null;

    const boundary = farm.farm_boundary?.geometry?.coordinates;
    const calculatePolygonCentroid = (coordinates) => {
        if (!coordinates || !coordinates[0] || !coordinates[0].length) {
            return null;
        }

        const points = coordinates[0];
        let area = 0;
        let lat = 0;
        let lng = 0;
        const pointCount = points.length;

        for (let i = 0; i < pointCount; i++) {
            const j = (i + 1) % pointCount;
            const p1 = points[i];
            const p2 = points[j];

            const f = p1[0] * p2[1] - p2[0] * p1[1];

            lat += (p1[1] + p2[1]) * f;
            lng += (p1[0] + p2[0]) * f;

            area += f / 2;
        }

        area = Math.abs(area);

        if (area === 0) {
            const sumLat = points.reduce((sum, point) => sum + point[1], 0);
            const sumLng = points.reduce((sum, point) => sum + point[0], 0);
            return [sumLat / pointCount, sumLng / pointCount];
        }

        const factor = 1 / (6 * area);
        return [
            lat * factor,
            lng * factor
        ];
    };

    const getMapCenter = (farm) => {
        try {
            const boundary = farm.farm_boundary?.geometry?.coordinates;
            if (boundary) {
                const centroid = calculatePolygonCentroid(boundary);
                if (centroid && !isNaN(centroid[0]) && !isNaN(centroid[1])) {
                    return centroid;
                }
            }
            return [farm.location.latitude, farm.location.longitude];
        } catch (error) {
            console.error('Error calculating map centroid:', error);
            return [farm.location.latitude, farm.location.longitude];
        }
    };

    const center = getMapCenter(farm);
    return (
      <div
        ref={panelRef}
        className={`fixed top-0 right-0 w-full md:w-2/3 lg:w-1/2 h-screen bg-gray-900 shadow-lg transform transition-transform duration-300 ease-in-out flex flex-col ${
          isPanelOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        {/* Panel Header */}
        <div className="bg-gray-900 z-10 p-4 border-b border-gray-800 flex justify-between items-center">
          <h2 className="text-xl font-semibold text-white">Farm Details</h2>
          <button
            onClick={closePanel}
            className="p-2 hover:bg-gray-800 rounded-full transition-colors"
          >
            <CloseIcon className="w-6 h-6 text-gray-400" />
          </button>
        </div>
  
        {/* Content Wrapper */}
        <div className="flex-1 overflow-y-auto">
          {/* Contribution Photos */}
          <div className="bg-gray-800 rounded-lg p-4 mb-4">
            <h3 className="text-white font-medium mb-4">Contributions</h3>
            {loadingContributions ? (
              <div className="flex items-center justify-center h-32">
                <Loader className="w-8 h-8 text-blue-500 animate-spin" />
              </div>
            ) : contributionError ? (
              <div className="text-red-500 text-sm">{contributionError}</div>
            ) : contributions.length > 0 ? (
              <div className="grid grid-cols-3 gap-2">
                {contributions.map((contribution) => (
                  <img
                    key={contribution.id}
                    src={contribution.photo_url}
                    alt="Farm Contribution"
                    className="w-full h-24 object-cover rounded-lg"
                  />
                ))}
              </div>
            ) : (
              <div className="text-gray-500 text-sm">No contributions available</div>
            )}
  
            {/* "+" Button to trigger upload options */}
            <div className="mt-4 flex justify-center relative">
              <button
                className="p-3 bg-blue-600 text-white rounded-full hover:bg-blue-500"
                onClick={togglePhotoOptions}
              >
                <Plus className="w-6 h-6" />
              </button>
  
              {/* Photo Upload Options Pop-up */}
              {showPhotoOptions && (
                <div
                  className="absolute bg-gray-800 p-4 rounded-lg shadow-lg text-white flex flex-col gap-4 mt-2 z-40"
                  ref={panelRef}
                >
                  <button className="bg-blue-500 hover:bg-blue-600 p-2 rounded-lg">
                    <label htmlFor="camera-upload" className="cursor-pointer">
                      Use Camera
                    </label>
                    <input
                      id="camera-upload"
                      type="file"
                      accept="image/*"
                      capture="environment"
                      onChange={handleCameraCapture}
                      className="hidden"
                    />
                  </button>
                  <button className="bg-green-500 hover:bg-green-600 p-2 rounded-lg">
                    <label htmlFor="gallery-upload" className="cursor-pointer">
                      Upload from Gallery
                    </label>
                    <input
                      id="gallery-upload"
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                      className="hidden"
                    />
                  </button>
                  <button
                    className="text-sm text-gray-400 underline mt-2"
                    onClick={togglePhotoOptions}
                  >
                    Cancel
                  </button>
                </div>
              )}
              {uploadError && (
                <div className="text-red-500 text-sm mt-2">{uploadError}</div>
              )}
            </div>
          </div>
  
          {/* Farm Info */}
          <div className="bg-gray-800 rounded-lg p-4 mb-4">
            <h3 className="text-white font-medium mb-4">Farm Information</h3>
            <div className="flex items-center gap-2 mb-2">
              <Crop className="w-5 h-5 text-blue-500" />
              <h3 className="text-lg font-medium text-white capitalize">
                {farm.crop_name ? farm.crop_name.toLowerCase() : 'Unknown Crop'}
              </h3>
            </div>
            <div className="text-gray-300 text-sm space-y-2">
              <div className="flex items-center">
                <Phone className="w-4 h-4 mr-3 text-blue-500" />
                <span>
                  {farm.phone_number
                    ? `+91 ${farm.phone_number}`
                    : 'Phone unavailable'}
                </span>
              </div>
              <div className="flex items-center">
                <Calendar className="w-4 h-4 mr-3 text-blue-500" />
                <span>
                  {farm.created_at ? formatDate(farm.created_at) : 'Time unavailable'}
                </span>
              </div>
              <div className="flex items-center">
                <MapPin className="w-4 h-4 mr-3 text-blue-500" />
                <span>
                  {farm.location
                    ? `${farm.location.latitude.toFixed(
                        6
                      )}, ${farm.location.longitude.toFixed(6)}`
                    : 'Location unavailable'}
                </span>
              </div>
            </div>
          </div>
  
          {/* Map */}
          {farm.farm_boundary && (
            <div className="bg-gray-800 rounded-lg p-4 mb-4">
              <h3 className="text-white font-medium mb-4">Farm Location</h3>
              <div className="h-48 rounded-lg overflow-hidden">
                <MapContainer
                  center={center}
                  zoom={17}
                  style={{ height: '100%', width: '100%' }}
                  zoomControl={false}
                >
                  <TileLayer
                    url="https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/512/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoic2hpbnRyZSIsImEiOiJjbHVhdmphbzUwbWF0MnFtbThhc3Z2MjZ4In0.-jeFD1_aqZvnrXpHZ8qT5Q"
                    maxZoom={19}
                    tileSize={512}
                    zoomOffset={-1}
                    attribution='© <a href="https://www.mapbox.com/about/maps/">Mapbox</a>'
                  />
                  <GeoJSON
                    data={farm.farm_boundary}
                    style={{
                      color: '#A020F0',
                      weight: 3,
                      fillColor: '#A020F0',
                      fillOpacity: 0.7,
                    }}
                  />
                </MapContainer>
              </div>
            </div>
          )}
  
          {/* NDVI Time Series */}
          <div className="bg-gray-800 rounded-lg p-4 mb-4">
            <h3 className="text-white font-medium mb-4">NDVI Time Series</h3>
            {ndviLoading ? (
              <div className="h-[200px] flex items-center justify-center">
                <Loader className="w-8 h-8 text-blue-500 animate-spin" />
              </div>
            ) : ndviError ? (
              <div className="h-[200px] flex items-center justify-center text-red-500">
                {ndviError}
              </div>
            ) : ndviData ? (
              <NDVIChart data={ndviData} />
            ) : (
              <div className="h-[200px] flex items-center justify-center text-gray-500">
                No NDVI data available
              </div>
            )}
          </div>
  
          {/* Grape Classification */}
          <div className="bg-gray-800 rounded-lg p-4 mb-4">
            <h3 className="text-white font-medium mb-4">Grape Field Classification</h3>
            {grapeLoading ? (
              <div className="h-[100px] flex items-center justify-center">
                <Loader className="w-8 h-8 text-blue-500 animate-spin" />
              </div>
            ) : grapeError ? (
              <div className="text-red-500 text-center py-4">{grapeError}</div>
            ) : grapeData ? (
              <div className="space-y-3 text-gray-300">
                <div className="flex items-center justify-between">
                  <span>Classification:</span>
                  <span
                    className={`font-semibold ${
                      grapeData.is_grape ? 'text-green-500' : 'text-red-500'
                    }`}
                  >
                    {grapeData.is_grape ? 'Grape Field' : 'Not a Grape Field'}
                  </span>
                </div>
                <div className="flex items-center justify-between">
                  <span>Confidence:</span>
                  <span>{(grapeData.grape_proportion * 100).toFixed(1)}%</span>
                </div>
                <div className="flex items-center justify-between">
                  <span>Images Analyzed:</span>
                  <span>{grapeData.number_of_images}</span>
                </div>
                <div className="flex items-center justify-between">
                  <span>Classification Date:</span>
                  <span>{formatDate(grapeData.classification_date)}</span>
                </div>
              </div>
            ) : (
              <div className="text-gray-500 text-center py-4">
                No classification data available
              </div>
            )}
          </div>
  
          {/* Phenology Dates */}
          <div className="bg-gray-800 rounded-lg p-4 mb-4">
            <h3 className="text-white font-medium mb-4">Growth Cycle Predictions</h3>
            {phenologyLoading ? (
              <div className="h-[200px] flex items-center justify-center">
                <Loader className="w-8 h-8 text-blue-500 animate-spin" />
              </div>
            ) : phenologyError ? (
              <div className="bg-red-900/20 text-red-500 p-4 rounded-lg">
                {phenologyError}
              </div>
            ) : phenologyData ? (
              <PhenologyDates data={phenologyData} />
            ) : (
              <div className="text-gray-500 text-center py-4">
                No growth cycle predictions available
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };
  

export default SlidePanel;
