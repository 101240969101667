import React, { useState } from 'react';
import { User } from 'lucide-react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for routing
import { UserCircleIcon } from '@heroicons/react/24/solid';

const ProfileIcon = ({ phoneNumber }) => {
  const navigate = useNavigate(); // Initialize navigate function


  const handleProfileClick = () => {
    navigate(`/profile/${phoneNumber}`); // Navigate to the profile page when clicked
  };

  return (
    <div className="relative">
      {/* Profile Icon Button */}
      <button onClick={handleProfileClick} className="p-2 rounded-full hover:bg-gray-700 transition-colors">
        <UserCircleIcon className="w-10 h-10 text-white" />
      </button>
    </div>
  );
};

export default ProfileIcon;
