// utils.js
export const getLocation = (timeoutDuration = 10000) => {
  return new Promise((resolve, reject) => {
    if (!navigator.geolocation) {
      reject(new Error('Geolocation is not supported by your browser.'));
    } else {
      const options = {
        enableHighAccuracy: true,
        timeout: timeoutDuration, // Set maximum time allowed for location retrieval
        maximumAge: 0,
      };

      const onSuccess = (position) => {
        resolve({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          accuracy: position.coords.accuracy,
        });
      };

      const onError = (error) => {
        switch (error.code) {
          case error.PERMISSION_DENIED:
            reject(new Error('Location permission was denied.'));
            break;
          case error.POSITION_UNAVAILABLE:
            reject(new Error('Location information is unavailable.'));
            break;
          case error.TIMEOUT:
            reject(new Error('Location request timed out.'));
            break;
          default:
            reject(new Error('An unknown error occurred while retrieving location.'));
            break;
        }
      };

      navigator.geolocation.getCurrentPosition(onSuccess, onError, options);

      // Manual timeout in case getCurrentPosition doesn't respect the timeout option
      const timer = setTimeout(() => {
        reject(new Error('Location request timed out.'));
      }, timeoutDuration + 1000); // Adding extra time as buffer

      // Clear the timer when either success or error callback is called
      const clearTimer = () => clearTimeout(timer);

      navigator.geolocation.getCurrentPosition(
        (position) => {
          clearTimer();
          onSuccess(position);
        },
        (error) => {
          clearTimer();
          onError(error);
        },
        options
      );
    }
  });
};


export const getSegmentation = async (latitude, longitude, setIsGettingSegmentation) => {
  try {
    setIsGettingSegmentation(true);
    const requestData = {
      lat: parseFloat(latitude),
      long: parseFloat(longitude),
      zoom: 16
    };

    console.log('Getting farm boundary...', requestData);

    const response = await fetch('/segmentation', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify(requestData)
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Farm boundary error:', {
        status: response.status,
        statusText: response.statusText,
        error: errorText
      });
      throw new Error(`Failed to get farm boundary: ${response.statusText}`);
    }

    const boundaryData = await response.json();
    console.log('Farm boundary received:', boundaryData);
    
    if (!boundaryData.type || 
      !boundaryData.geometry || 
      !boundaryData.geometry.type || 
      !boundaryData.geometry.coordinates ||
      !Array.isArray(boundaryData.geometry.coordinates)) {
      console.error('Invalid boundary data structure:', boundaryData);
      throw new Error('Invalid boundary data structure received');
    }

    return boundaryData;
  } catch (error) {
    console.error('Farm boundary error:', error);
    throw new Error('Failed to get farm boundary. Please try again.');
  } finally {
    setIsGettingSegmentation(false);
  }
};

export const validateData = (photo, location, cropName) => {
  if (!photo) {
    return { isValid: false, error: 'Photo is required' };
  }
  if (!location) {
    return { isValid: false, error: 'Location is required' };
  }
  if (!cropName.trim()) {
    return { isValid: false, error: 'Crop name is required' };
  }
  return { isValid: true, error: null };
};