import React, { useState, useEffect } from 'react';
import { MapPin, Calendar, Crop, Loader, Home, Grape } from 'lucide-react';
import {MapContainer, TileLayer, GeoJSON} from 'react-leaflet';
import 'leaflet/dist/leaflet.css'; // Import Leaflet CSS
import L from 'leaflet';

// Fix for default icon images not showing in React-Leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png').default,
  iconUrl: require('leaflet/dist/images/marker-icon.png').default,
  shadowUrl: require('leaflet/dist/images/marker-shadow.png').default,
});

// Farm Component
const Farm = ({ farm, onClick }) => {
const [contributions, setContributions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  useEffect(() => {
    const fetchContributions = async () => {
      try {
        const response = await fetch(`/api/contributions/farm/${farm.id}`);
        if (!response.ok) {
          throw new Error('Failed to fetch contributions');
        }
        const data = await response.json();
        setContributions(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchContributions();
  }, [farm.id]);

  console.log('Farm object:', farm);
  console.log('Farm Location Address:', farm.location.address);

  const cropIcon =
    farm.crop_name && farm.crop_name.toLowerCase() === 'grapes' ? Grape : Crop;

    const calculateCentroid = (boundary) => {
      if (!boundary || !boundary.geometry || !boundary.geometry.coordinates) {
        return [0, 0];
      }
  
      const coordinates = boundary.geometry.coordinates;
  
      // For Polygon, coordinates[0] is the outer ring
      const points = coordinates[0];
      let latSum = 0;
      let lngSum = 0;
      let numPoints = points.length;
  
      points.forEach(point => {
        lngSum += point[0];
        latSum += point[1];
      });
  
      return [latSum / numPoints, lngSum / numPoints];
    };
  
    // Get map center
    const mapCenter = farm.farm_boundary
      ? calculateCentroid(farm.farm_boundary)
      : [farm.location?.latitude || 0, farm.location?.longitude || 0];
  
  return (
    <div
      className="bg-gray-900 rounded-lg overflow-hidden shadow-lg hover:bg-gray-800 transition-colors flex"
      onClick={onClick}
    >
      {/* Farm Details */}
      <div className="flex-1 p-4">
      <div className="flex items-center gap-2 mb-4">
      {/* Render the chosen icon */}
      {React.createElement(cropIcon, { className: 'w-5 h-5 text-green-500' })}
      <h2 className="text-xl font-semibold text-white capitalize">
        {farm.crop_name ? farm.crop_name.toLowerCase() : 'Unknown Crop'}
      </h2>
    </div>

        {/* Farm Address */}


        {/* Location */}
        <div className="space-y-3 text-gray-300">
          <div className="flex items-center">
            <MapPin className="w-4 h-4 mr-3 text-blue-500" />
            <span className="text-sm">
              {farm.location && farm.location.address
                ? `${farm.location.address}`
                : 'Location unavailable'}
            </span>
          </div>

          {/* Last Updated */}
          <div className="flex items-center">
            <Calendar className="w-4 h-4 mr-3 text-blue-500" />
            <span className="text-sm">
              {farm.last_updated ? formatDate(farm.last_updated) : 'Time unavailable'}
            </span>
          </div>
        </div>
        <div className="mb-3"></div>

        
{/* Flex container for map and contribution */}
<div className="flex flex-col lg:flex-row lg:space-x-4">
          {/* Map Section */}
          {farm.farm_boundary && (
            <div className="bg-gray-800 rounded-lg p-2 mt-4 lg:mt-0 w-full lg:w-1/2">
              <h3 className="text-white font-medium mb-2">Farm Location</h3>
              <div className="h-48 rounded-lg overflow-hidden" style={{ zIndex: 1 }}>
                <MapContainer
                  center={mapCenter}
                  zoom={17}
                  style={{ height: '100%', width: '100%' }}
                  zoomControl={false}
                >
                  <TileLayer
                    url="https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/512/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoic2hpbnRyZSIsImEiOiJjbHVhdmphbzUwbWF0MnFtbThhc3Z2MjZ4In0.-jeFD1_aqZvnrXpHZ8qT5Q"
                    maxZoom={19}
                    tileSize={512}
                    zoomOffset={-1}
                    attribution='© <a href="https://www.mapbox.com/about/maps/">Mapbox</a>'
                  />
                  <GeoJSON
                    data={farm.farm_boundary}
                    style={{
                      color: '#A020F0',
                      weight: 3,
                      fillColor: '#A020F0',
                      fillOpacity: 0.7,
                    }}
                  />
                </MapContainer>
              </div>
            </div>
          )}

          {/* Contribution Photos */}
          <div className="p-4 w-full lg:w-1/2">
            {loading ? (
              <div className="flex items-center justify-center">
                <Loader className="w-5 h-5 text-blue-500 animate-spin" />
              </div>
            ) : error ? (
              <div className="text-red-500 text-sm">Error: {error}</div>
            ) : contributions.length === 0 ? (
              <div className="text-gray-500 text-sm">No contributions available</div>
            ) : (
              <div className="grid grid-cols-3 gap-2">
                {contributions.slice(0, 5).map((contribution) => (
                  <img
                    key={contribution.id}
                    src={contribution.photo_url}
                    alt="Farm Contribution"
                    className="w-full h-16 object-cover rounded-lg"
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Farm;
