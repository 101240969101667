import React from 'react';
import Select from 'react-select';

const CropInputScreen = ({
  photo,
  cropName,
  setCropName,
  error,
  setError,
  setCurrentScreen,
  resetForm
}) => {
  const handleContinue = () => {
    if (!cropName.trim()) {
      setError('Please enter a crop name');
      return;
    }
    setCurrentScreen('preview');
  };

  const options = [
    { value: 'Grapes', label: 'Grapes' },
    { value: 'Others', label: 'Others' }
  ];

  const handleDropdownChange = (selectedOption) => {
    setError('');
    setCropName(selectedOption.value === 'Others' ? '' : selectedOption.value);
  };

  return (
    <div className="space-y-3 border-2 border-green-700 p-6 rounded-lg shadow-lg mt-8">
      
      {/* Image Section */}
      <img src={photo} alt="Farm" className="w-48 h-48 mx-auto object-cover rounded-lg shadow-lg mt-2" />

      {/* Title and Description */}
      <h2 className="text-2xl font-bold text-center text-white mt-6">Select Your Crop</h2>
      <p className="text-gray-400 text-center max-w-md">
        Choose your crop type from the dropdown below.
      </p>

      {/* Dropdown Section */}
      <div className="w-full max-w-md space-y-2">
        
        <Select
          options={options}
          onChange={handleDropdownChange}
          className="w-full"
          placeholder="Select crop"
          styles={{
            control: (base) => ({
              ...base,
              backgroundColor: '#1F2937', // bg-gray-900 equivalent
              borderColor: '#374151', // border-gray-700 equivalent
              color: 'white',
              boxShadow: 'none',
              '&:hover': { borderColor: '#4B5563' } // hover:border-gray-600
            }),
            singleValue: (base) => ({
              ...base,
              color: 'white'
            }),
            placeholder: (base) => ({
              ...base,
              color: '#9CA3AF' // text-gray-400 equivalent
            }),
            menu: (base) => ({
              ...base,
              backgroundColor: '#1F2937', // Set dropdown menu background to dark
              color: 'white',
            }),
            option: (base, state) => ({
              ...base,
              backgroundColor: state.isFocused ? '#374151' : '#1F2937', // Dark background with a lighter shade on hover
              color: 'white',
              '&:active': {
                backgroundColor: '#4B5563' // Even lighter shade when active
              }
            })
          }}
        />
        
        {error && <p className="mt-2 text-red-500 text-sm font-semibold">{error}</p>}
      </div>

      {/* Buttons Section */}
      <div className="w-full max-w-md space-y-4 mt-6">
        <button
          onClick={handleContinue}
          className="w-full bg-blue-500 text-white py-3 rounded-lg font-semibold hover:bg-blue-600 transition-transform duration-200 shadow-lg hover:shadow-xl transform hover:scale-105"
        >
          Continue
        </button>

        <button
          onClick={resetForm}
          className="w-full bg-gray-700 text-white py-3 rounded-lg font-semibold hover:bg-gray-600 transition-transform duration-200 shadow-lg hover:shadow-xl transform hover:scale-105"
        >
          Retake Photo
        </button>
      </div>
    </div>
  );
};

export default CropInputScreen;
