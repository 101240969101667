import React, { useState, useEffect, useRef } from 'react';
import { MapPin, Map, XCircle } from 'lucide-react';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import { getLocation } from './utils';
import 'leaflet/dist/leaflet.css';  // Import Leaflet CSS
import L from 'leaflet';

import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

// Fix Leaflet's default icon paths

const customMarkerIcon = new L.Icon({
  iconUrl: `${process.env.PUBLIC_URL}/marker-icon.png`,
  iconRetinaUrl: `${process.env.PUBLIC_URL}/marker-icon-2x.png`,
  shadowUrl: `${process.env.PUBLIC_URL}/marker-shadow.png`,
  iconSize: [25, 41],      // Adjust if necessary
  iconAnchor: [12, 41],    // Adjust if necessary
  popupAnchor: [1, -34],   // Adjust if necessary
  shadowSize: [41, 41],    // Adjust if necessary
});

const LocationPicker = ({ setSelectedLocation }) => {
  useMapEvents({
    click(e) {
      setSelectedLocation(e.latlng);  // Set the location clicked on the map
    },
  });

  return null;
};

const LocationSelectionScreen = ({
  photo,
  setLocation,
  setCurrentScreen
}) => {
  const [showMap, setShowMap] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const handleLocationConfirm = async () => {
    try {
      // Use the selected location from the map if it exists
      if (selectedLocation) {
        setLocation({
          latitude: selectedLocation.lat,
          longitude: selectedLocation.lng,
          accuracy: 0
        });
        setCurrentScreen('crop');
        setShowMap(false);
      } else {
        // Otherwise, use the current location from geolocation
        const loc = await getLocation();
        if (mounted.current) {
          setLocation(loc);
          setCurrentScreen('crop');
          setShowMap(false);
        }
      }
    } catch (error) {
      if (mounted.current) {
        // Handle error appropriately
        console.error('Location error:', error);
      }
    }
  };

  return (
    <div className="space-y-3 border-2 border-green-700 p-6 rounded-lg shadow-lg mt-8">
      <div className=" rounded-lg overflow-hidden">
        <img src={photo} alt="Farm" className="w-48 h-48 mx-auto object-cover rounded-lg shadow-lg mt-2" />
      </div>

      <h2 className="text-2xl font-bold text-center text-white">Select Your Location</h2>
      <p className="text-gray-600 text-center">
        Choose your location by either using your current location or selecting it on the map.
      </p>

      <div className="space-y-4">
        <button
          onClick={() => getLocation().then(loc => {
            setLocation(loc);
            setCurrentScreen('crop');
          })}
          className="w-full bg-blue-500 text-white py-3 rounded-lg font-semibold hover:bg-blue-600 transition-colors flex items-center justify-center gap-2"
        >
          <MapPin className="w-5 h-5" />
          Use Current Location
        </button>

        <button
          onClick={() => setShowMap(true)}
          className="w-full bg-blue-500 text-white py-3 rounded-lg font-semibold hover:bg-blue-600 transition-colors flex items-center justify-center gap-2"
        >
          <Map className="w-5 h-5" />
          Pick on Map
        </button>
      </div>

      {showMap && (
        <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center p-4 z-50">
          <div className="bg-gray-800 rounded-lg w-full max-w-xl overflow-hidden">
          <div className="p-4 flex justify-between items-center border-b border-gray-700">
          <h3 className="text-lg font-semibold text-white">Select Location</h3>
          <button onClick={() => setShowMap(false)} className="text-gray-400 hover:text-white transition-colors">
          <XCircle className="w-6 h-6" />
          </button>
            </div>
            <div className="h-96 relative">
              <MapContainer
                center={[17.09084422544972, 74.77181375773363]}
                zoom={13}
                style={{ height: '100%', width: '100%' }}
              >
                <TileLayer 
                  url="https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/512/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoic2hpbnRyZSIsImEiOiJjbHVhdmphbzUwbWF0MnFtbThhc3Z2MjZ4In0.-jeFD1_aqZvnrXpHZ8qT5Q"
                  maxZoom={19}
                  tileSize={512}
                  zoomOffset={-1}
                  attribution='© <a href="https://www.mapbox.com/about/maps/">Mapbox</a>'
                />
                <LocationPicker setSelectedLocation={setSelectedLocation} />
                {selectedLocation && <Marker position={selectedLocation} icon={customMarkerIcon} />}
              </MapContainer>
            </div>
            <div className="p-4 border-t border-gray-700">
              <button
                onClick={handleLocationConfirm}
                className="w-full bg-blue-500 text-white py-2 rounded-lg font-semibold hover:bg-blue-600 transition-colors"
              >
                Confirm Location
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LocationSelectionScreen;
