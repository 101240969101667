import React, { useRef, useEffect } from 'react';
import { Camera, Upload, ArrowRight } from 'lucide-react';

import icon2 from './product-inside-field.webp';
import icon1 from  './farmer-inside-field.webp';
const PhotoSelectionScreen = ({
  setPhoto,
  setTimestamp,
  setCurrentScreen, 
  setError
}) => {
  const fileInputRef = useRef(null);
  const mounted = useRef(true);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const handleFileSelect = async (event) => {
    const file = event.target.files[0];
    if (file && mounted.current) {
      try {
        const base64 = await convertToBase64(file);
        if (mounted.current) {
          setPhoto(base64);
          setTimestamp(new Date().toISOString());
          setCurrentScreen('location-selection');
        }
      } catch (err) {
        if (mounted.current) {
          setError('Failed to process image');
        }
      }
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };

  return (
    <div className="space-y-3 border-2 border-green-700 p-6 rounded-lg shadow-lg mt-6">
      {/* Heading */}
      <h2 className="text-2xl text-white font-bold text-center">Register your farm</h2>
      
      {/* Description */}
      <p className="text-gray-500 text-center ">
        Stand in your field and take a photo of your crop using one of the options below.
      </p>
      
      {/* Buttons */}
      <div className="flex space-x-4 mt-4">
        <button
          onClick={() => {
            setCurrentScreen('camera');
          }}
          className="flex-1 bg-green-500 text-white py-3 rounded-lg font-semibold hover:bg-green-600 transition-colors duration-300 flex items-center justify-center gap-2"
        >
          <Camera className="w-5 h-5" />
          Use Camera
        </button>
        <button
          onClick={() => fileInputRef.current?.click()}
          className="flex-1 bg-blue-500 text-white py-3 rounded-lg font-semibold hover:bg-blue-600 transition-colors duration-300 flex items-center justify-center gap-2"
        >
          <Upload className="w-5 h-5" />
          Upload Photo
        </button>
      </div>

      {/* Additional Description */}
      <div className="text-sm text-gray-500 text-center mt-2">
        <p>
          <strong>Use Camera</strong> will request your location to segment your farm boundary.
        </p>
        <p>
          Or, <strong>Upload Photo</strong> from your gallery.
        </p>
      </div>

      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        className="hidden"
        onChange={handleFileSelect}
      />

      {/* Image Preview Section */}
      <div className="flex items-center justify-center space-x-4 mt-8">
        <div className="w-1/2 flex flex-col items-center">
          <img
            src={icon1}
            alt="Farmer standing in the field"
            className="w-full h-48 object-cover rounded-lg transition-transform duration-300 hover:scale-105"
          />
          <p className="text-center mt-2 text-sm text-gray-500">Stand in your field</p>
        </div>
        <ArrowRight className="text-gray-400 w-6 h-6" />
        <div className="w-1/2 flex flex-col items-center">
          <img
            src={icon2}
            alt="Clicking photo of crop"
            className="w-full h-48 object-cover rounded-lg transition-transform duration-300 hover:scale-105"
          />
          <p className="text-center mt-2 text-sm text-gray-500">Take a photo of your crop</p>
        </div>
      </div>
    </div>
  );
};

export default PhotoSelectionScreen;
