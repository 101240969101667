// RoleSelection.js
import React from 'react';

const RoleSelection = ({ onSelectRole }) => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-black text-white px-4">
      <h2 className="text-4xl font-bold mb-8 text-center">
        Welcome to <span className="text-blue-500">coopFarms</span>
      </h2>
      <p className="text-lg text-gray-300 mb-8 text-center max-w-md">
        Please select your role to continue. Are you a Farmer or a Trader?
      </p>

      <div className="flex flex-col gap-4 w-full max-w-xs">
        <button
          onClick={() => onSelectRole('farmer')}
          className="bg-blue-500 text-white py-3 rounded-lg font-semibold text-lg hover:bg-blue-600 transition duration-200"
        >
          Farmer
        </button>
        <button
          onClick={() => onSelectRole('trader')}
          className="bg-blue-500 text-white py-3 rounded-lg font-semibold text-lg hover:bg-blue-600 transition duration-200"
        >
          Trader
        </button>
      </div>
    </div>
  );
};

export default RoleSelection;
